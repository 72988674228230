var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: {
                align: "center",
                justify: "center",
                cols: "12",
                sm: "8",
              },
            },
            [
              _c(
                "v-col",
                { attrs: { align: "start", justify: "start" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.redirectBack },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                [
                  _c(
                    "v-toolbar",
                    { staticClass: "secondary ma-4" },
                    [
                      _c(
                        "v-toolbar-title",
                        { staticClass: "white--text text-h4" },
                        [_vm._v(" Request Refund Form ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "px-2" },
                    [
                      _c("v-container", [
                        _vm.selectedPaymentMethod != null
                          ? _c(
                              "div",
                              {
                                staticClass: "d-flex flex-column justify-start",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "d-flex justify-start font-weight-bold",
                                  },
                                  [_vm._v(" Remark : ")]
                                ),
                                _c("v-textarea", {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    rules: _vm.rulesMaxLength,
                                    placeholder: "Start typing your remark...",
                                    counter: 500,
                                    hint: "Max 500 characters",
                                  },
                                  model: {
                                    value: _vm.remark,
                                    callback: function ($$v) {
                                      _vm.remark = $$v
                                    },
                                    expression: "remark",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              { attrs: { color: "primary", plain: "" } },
                              [_vm._v(" Cancel ")]
                            ),
                            _c("v-btn", { attrs: { color: "primary" } }, [
                              _vm._v(" Confirm "),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }